<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12 text-right">
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
						</div>
					</div>
					<div class="row pt15">
						<div class="col-md-12">
							<a-table :columns="columns" :dataSource="projectsList">
								<div slot="full_name" slot-scope="record" class="text-left">
									{{ record.full_name }}
								</div>
								<div slot="code" slot-scope="record">
									{{ record.code.toUpperCase() }}
								</div>
								<div slot="action" slot-scope="record">
									<a-button type="info" size="small" icon="eye" @click="onEditRecord(record.id)" />
								</div>
							</a-table>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	name: 'projectsView',
	computed: {
		...mapGetters('projects', ['projectsList', 'spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('auth', ['userData']),
		columns() {
			let columns = [
				{
					title: this.$t('projectsModule.tableHeaders.key'),
					scopedSlots: { customRender: 'code' },
					align: 'center',
					width: '20%',
				},
				{
					title: this.$t('projectsModule.tableHeaders.name'),
					dataIndex: 'name',
				},
				{
					title: this.$t('general.actions'),
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '10%',
				},
			]
			return columns
		},
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			this.$store.dispatch('projects/GET')
		},
		onReload() {
			this.initModule()
		},
		onEditRecord(project_id) {
			this.$store.dispatch('projects/GET_ONE', {
				project_id,
			}).then(() => {
				this.$router.push(`/supplier/projects/${project_id}`)
			})
		},
	},
}
</script>